import React, { Component } from 'react';
import './../styles/styles.css';
const Landing = () => {
  return (
    <div className="full-width fullWindow">
      <div className="header full-width">
        <div className="headerTop full-width">
          <div className="wrapper">
            <a className="anchor-link">
              <i class="fa fa-map-marker" aria-hidden="true" />
              Umar Bin Abdul Aziz Road
            </a>
            <a className="anchor-link">
              <i class="fa fa-phone" aria-hidden="true" />
              011 477 8381
            </a>
            <a className="anchor-link">
              <i class="fa fa-envelope" aria-hidden="true" />
              info@foodcourt.com
            </a>
          </div>
        </div>
        <div className="headerBottom full-width">
          <div className="wrapper">
            <a href="#" className="anchor-link">
              <img src="/images/top-logo.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="banner full-width">
        <div className="wrapper">
          <div className="bannerLeft">
            <h1>Download the App Now</h1>
            <div className="full-width bannerCTA">
              <a href="#">
                <img src="/images/GooglePlayBadge.png" />
              </a>
              <a href="#">
                <img src="/images/AppStoreBadge.png" />
              </a>
            </div>
          </div>
          <div className="bannerRight">
            <a href="#">
              <img src="/images/bannerRightImage.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer full-width">
        <div className="wrapper">
          <span>Copyright © 2023 FoodCourt All rights reserved.</span>
          <div className="footerSocial">
            <a target="_blank" href="#">
              <i class="fa fa-facebook" aria-hidden="true" />
            </a>
            <a target="_blank" href="#">
              <i class="fa fa-twitter" aria-hidden="true" />
            </a>
            <a target="_blank" href="#">
              <i class="fa fa-youtube-play" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
