import React from 'react';
import '../../styles/privacyStyles.css';

const Privacy = () => {
  return (
    <body class="innerPage landing-page bgimg-main">
      <div className="header-section innerPage full-width">
        <div className="wrapper flex-center">
          <div className="logo full-width">
            <a href="index.html">
              {' '}
              <h1
                style={{
                  margin: '0',
                }}
              >
                Food Court
              </h1>{' '}
            </a>
          </div>
        </div>
      </div>

      <div className="banner-section innerPage full-width">
        <div className="wrapper">
          <h1>Delete Account</h1>
          <h5>We're Here to Assist You</h5>
          <p>
            At Food Court, Our primary aim is to make this process as seamless and straightforward
            as possible. If you face any issues or have questions about our services, our dedicated
            support team is always here to help.
          </p>
          <h5>To delete your account from our platform follow the below steps:</h5>
          <p>1] Login into your account.</p>
          <p>2] Go to 'Settings'.</p>
          <p>3] Scroll to the bottom of the screen</p>
          <p>4] Use the 'Delete Account' option to delete your account.</p>
          <p>
            Alternatively, You can email us at info@foodcourtapps.com with your registered email
            address to request account deletion, and we will assist you with the process.
          </p>
          <p>
            We value the trust you place in Food Court, and we're committed to ensuring your
            experience with us is smooth and satisfactory. Always remember, we're just an email
            away, ready to assist.
          </p>
        </div>
      </div>

      <div className="footer-section innerPage full-width">
        <div className="wrapper">
          <div style={{ textAlign: 'center', float: 'left', width: '100%' }}>
            <a href="https://foodcourt-live.s3.me-south-1.amazonaws.com/food+court+privacy+policy.pdf">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Privacy;
