import React, { useEffect, useState } from 'react';

import './styles/base.css';
import './styles/styles.css';
import Landing from './components/landingpage';
import HtmlRenderer from './components/htmlRenderer';
import { RouterProvider } from 'react-router-dom';
import router from './Routing';

const App = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtml = async () => {
      const response = await fetch(`${window.location.pathname}.html`);
      const html = await response.text();
      setHtmlContent(html);
    };
    fetchHtml();
  }, []);

  return (
    <div>
      <HtmlRenderer htmlContent={htmlContent} />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
